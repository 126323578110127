import React from "react";
import Nav from "../components/Nav";
import Carousel from "../components/Carousel";
import "./index.css";
import Story from "../components/Homepage/Story";
import Reviews from "../components/Homepage/Reviews";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";
import "../components/i18n";

import slide1 from "../images/homeCarousel/slide1.jpg";
import slide2 from "../images/homeCarousel/slide2.jpg";
import slide3 from "../images/homeCarousel/slide3.jpg";
import slide4 from "../images/homeCarousel/slide4.jpg";
import slide5 from "../images/homeCarousel/slide5.jpg";
import slide6 from "../images/homeCarousel/slide6.jpg";
import slide7 from "../images/homeCarousel/slide7.jpg";
const Map = loadable(() => import("../components/Homepage/Map"));

// These two variables hold text and images
// for carousel since its shared component
// between homepage and restaurant page
const content = {
  heading: "La Noce",
  slogan: "Restaurant & Pub",
};
const slides = [slide1, slide2, slide3, slide4, slide5, slide6, slide7];

export default function Index() {
  return (
    <>
      <Helmet title="La Noce - Restaurant & Pub" defer={false} />

      <Nav />
      <Carousel content={content} slides={slides} />
      <Story />
      <Reviews />
      <Map />
      <Footer />
    </>
  );
}
