import React from "react";
import video from "../../videos/halloween.mp4";
import "video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import { useTranslation } from "react-i18next";
import styles from "./story.module.css";

export default function Story() {
  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <section className={styles.storySection}>
      <h1 className={styles.heading}>{t("home.story.title")}</h1>
      <div className={styles.container}>
        <p className={styles.story}>{t("home.story.description")}</p>
        <Player playsInline src={video} />
      </div>
    </section>
  );
}
