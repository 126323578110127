import React from "react";
import "./reviews.module.css";
import Slider from "react-slick";
import styles from "./reviews.module.css";
import { useTranslation } from "react-i18next";
export default function Reviews() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1365,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t } = useTranslation("translation", { useSuspense: false });
  const reviews = [
    [`"${t("home.reviews.1")}"`, "Beqqi Lahcen"],
    [`"${t("home.reviews.2")}"`, "Thiranai Thongin"],
    [`"${t("home.reviews.3")}"`, "Ashish Churi"],
    [`"${t("home.reviews.4")}"`, "Khaoula Moufarih"],
    [`"${t("home.reviews.5")}"`, "Mohammed Afqir"],
    [`"${t("home.reviews.6")}"`, "Souhail Lahlali"],
    [`"${t("home.reviews.7")}"`, "Rob Noort"],
    [`"${t("home.reviews.8")}"`, "Said Ait lmoudden"],
    [`"${t("home.reviews.9")}"`, "Yassine Makboul"],
    [`"${t("home.reviews.10")}"`, "Marouane Bouhmani"],
    [`"${t("home.reviews.11")}"`, "Tarik Farih"],
  ];
  return (
    <section className={styles.reviewsSection} id="reviews">
      <Slider {...settings} className={styles.slider}>
        {reviews.map((review, i) => (
          <div key={i}>
            <p className={styles.reviewText}>{review[0]}</p>
            <p className={styles.reviewer}>- {review[1]}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
}
